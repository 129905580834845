import React from 'react';
import { Helmet } from 'react-helmet';

const Index = () => (
	<div>
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>

		OK
	</div>
);

export default Index;
